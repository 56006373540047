// extracted by mini-css-extract-plugin
export var finger = "main-module--finger--de64b";
export var hero = "main-module--hero--c4e2f";
export var hero_cta = "main-module--hero_cta--30345";
export var intro = "main-module--intro--8cfc7";
export var mouse = "main-module--mouse--81d1a";
export var quote_container = "main-module--quote_container--d4953";
export var scroll = "main-module--scroll--78cc9";
export var section_title = "main-module--section_title--73f9e";
export var subtext = "main-module--subtext--260f2";
export var team = "main-module--team--04859";
export var team_description = "main-module--team_description--3bd60";
export var team_image = "main-module--team_image--31c66";
export var team_list = "main-module--team_list--36e7f";
export var team_subtext = "main-module--team_subtext--aabed";
export var team_title = "main-module--team_title--9e07b";
export var teams = "main-module--teams--d9a8e";