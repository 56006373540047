// extracted by mini-css-extract-plugin
export var active = "portfolio-module--active--95281";
export var categories = "portfolio-module--categories--ce75d";
export var categoriesGrid = "portfolio-module--categories-grid--534b1";
export var grid_item1 = "portfolio-module--grid_item1--26918";
export var grid_item2 = "portfolio-module--grid_item2--29d8b";
export var grid_item3 = "portfolio-module--grid_item3--4a14f";
export var grid_item4 = "portfolio-module--grid_item4--4f51b";
export var grid_item5 = "portfolio-module--grid_item5--b9a4f";
export var grid_item6 = "portfolio-module--grid_item6--41ae4";
export var grid_item7 = "portfolio-module--grid_item7--2ba71";
export var grid_item8 = "portfolio-module--grid_item8--ebdb2";
export var img_responsive = "portfolio-module--img_responsive--0a360";
export var item_info = "portfolio-module--item_info--8d185";
export var portfolio = "portfolio-module--portfolio--e9628";
export var portfolioInner = "portfolio-module--portfolio-inner--fee45";
export var portfolio_container = "portfolio-module--portfolio_container--2ddcc";
export var portfolio_container_reversed = "portfolio-module--portfolio_container_reversed--67640";
export var portfolio_filter = "portfolio-module--portfolio_filter--c097b";
export var portfolio_grid_item_second = "portfolio-module--portfolio_grid_item_second--46ab4";
export var portfolio_heading = "portfolio-module--portfolio_heading--34e31";
export var portfolio_item = "portfolio-module--portfolio_item--e4e0e";
export var portfolio_item_container = "portfolio-module--portfolio_item_container--cce8e";
export var portfolio_item_hover = "portfolio-module--portfolio_item_hover--2905a";
export var portfolio_item_overlay1 = "portfolio-module--portfolio_item_overlay1--a18ee";
export var portfolio_item_overlay2 = "portfolio-module--portfolio_item_overlay2--00477";
export var portfolio_item_overlay3 = "portfolio-module--portfolio_item_overlay3--6f28b";